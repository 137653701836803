import { defineStore } from "pinia";

export const useDetailStore = defineStore("detail", {
  state: () => ({
    // dev only
    disableTransitions: false,

    media: null,
    trailer: null,
    selectedSeason: null,

    transitionState: "hero",
    transitionTimer: null,
    player: null,
    nextToWatch: null,
    episodes: null,
  }),
  getters: {
    isPodcast() {
      if (!this.media) return false;
      const computedValue = computed(() => useMediaType(this.media).isPodcast);
      return computedValue?.value || false;
    },
  },
  actions: {
    async fetchEpisodes(season) {
      const { externalId } = season;
      const { locale } = useI18n();

      const response = await useGetMethod("/api/biz/tv/show/episodes", {
        season_external_id: externalId,
        language_code: locale,
        client: "json",
      });

      return response;
    },
  },
});

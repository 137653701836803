<template>
  <div
    class="flex gap-x-1 items-center rounded-full border border-white/10 bg-white/5 px-2 py-1 backdrop-blur-sm"
  >
    <slot name="prefix"></slot>
    <span class="font-Abu text-label-medium text-white opacity-[0.87]">
      <slot></slot>
    </span>
  </div>
</template>

<template>
  <div class="flex items-center gap-x-3">
    <UiTagRanked v-if="toptenTag" :rank="toptenTag.rank">
      {{ $t(toptenTag.value) }}
    </UiTagRanked>
    <UiTag v-if="newTag">
      <template #prefix>
        <Icon name="IconFire" class="rtl:-scale-x-100"></Icon>
      </template>
      {{ $t(newTag.value) }}
    </UiTag>

    <UiTag v-if="exclusiveTag">
      {{ exclusiveTag.value }}
    </UiTag>

    <UiTag v-if="comingSoonTag">
      {{ comingSoonTag.value }}
    </UiTag>
  </div>
</template>

<script setup>
const props = defineProps({
  media: Object,
  inDetail: Boolean,
});

const route = useRoute();

const contentType = computed(() => {
  return props.media?.type || "??";
});

const extrafields = computed(() => {
  switch (contentType.value) {
    case "Movie":
      return (
        props.media.aggregatedVideo?.extrafields || props.media.extrafields
      );

    case "Season":
    case "Series":
      if (props.inDetail) {
        let season;
        if (route.query?.externalSeasonId) {
          season = props.media.tvShowSeasons.find(
            (s) => s.externalId == route.query.externalSeasonId
          );
        } else {
          season =
            props.media?.tvShowSeason ||
            props.media.tvShowSeasons?.[0] ||
            props.media;
        }

        return season.extrafields;
      }

      const obj =
        props.media.tvShowSeries?.extrafields || props.media.extrafields || [];
      return obj;
    default:
      return props.media?.extrafields || [];
  }
});

const newTag = computed(() => {
  const tag = extrafields.value?.find((e) => e.name == "isNew") || {};
  if (Object.keys(tag).length === 0) {
    return null;
  }

  return tag;
});

const toptenTag = computed(() => {
  const tag = extrafields.value?.find((e) => e.name == "Top10") || {};
  if (Object.keys(tag).length === 0) {
    return null;
  }

  return tag;
});

const exclusiveTag = computed(() => {
  const tag = extrafields.value?.some((e) => e.name == "Exclusive");

  if (!tag) {
    return null;
  }

  return extrafields.value.find((e) => e.name == "Exclusive");
});

const comingSoonTag = computed(() => {
  const tag = extrafields.value?.some((e) => e.name == "Coming_Soon");

  if (!tag) {
    return null;
  }

  return extrafields.value.find((e) => e.name == "Coming_Soon");
});
</script>

<style lang="scss" scoped></style>

<template>
  <div class="overlay__description">
    <p
      :class="[
        inDetail
          ? 'md:text-body-medium lg:text-body-big'
          : 'md:text-body-medium lg:text-title-big',
      ]"
      ref="paragraph"
      class="pt-4 line-clamp-3 tracking-wider text-white opacity-[0.87]"
    >
      {{ description }}
    </p>

    <button
      v-if="inDetail && description?.length > 180"
      class="text-white opacity-[0.87] text-button-medium underline underline-offset-2"
      @click="onMoreClick"
    >
      <span>
        {{ $t("More") }}
      </span>
    </button>

    <Dialog
      :modal="modalState"
      modal-class="z-[999] p-4 md:p-10 lg:p-20"
      container-class="max-w-[720px] !h-fit !max-h-min "
      @onOverlayClick="modalState = false"
    >
      <div class="px-11 py-20 gradient">
        <p class="text-body-big text-white opacity-[0.87]">
          {{ description }}
        </p>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
const props = defineProps({
  media: Object,
  inDetail: {
    type: Boolean,
    default: () => false,
  },
});

const emit = defineEmits("onDescriptionModal");

const modalState = ref(false);
const paragraph = ref();

const route = useRoute();

const mediaType = computed(() => {
  return route.query.type || props.media.type;
});

const description = computed(() => {
  if (!props.media) return null;
  switch (mediaType.value) {
    case "Season":
    case "Series":
      if (route.query.contentType == "Podcast") {
        return props.media.description;
      }

      if (props.inDetail) {
        let season;

        if (route.query?.externalSeasonId) {
          season = props.media.tvShowSeasons.find(
            (s) => s.externalId == route.query.externalSeasonId
          );
        } else {
          season =
            props?.media?.tvShowSeason || props?.media?.tvShowSeasons?.[0];
        }

        if (season) {
          return season.description;
        }

        return props.media.description || "";
      }

      const { tvShowSeason, tvShowSeasons } = props.media;
      if (tvShowSeason) {
        return tvShowSeason.description;
      }

      if (Array.isArray(tvShowSeasons) && tvShowSeasons.length > 0) {
        return tvShowSeasons[0].description;
      }

      return props.media.tvShowSeries?.description || props.media.description;

    case "Movie":
      return (props.media.aggregatedVideo || props.media).description;
    default:
      return props.media.description;
  }
});

const onMoreClick = () => {
  modalState.value = true;
};

watch(modalState, () => emit("onDescriptionModal", modalState.value));
</script>

<style lang="scss" scoped>
.gradient {
  background: radial-gradient(
      100% 80% at 100% 0%,
      rgba(208, 45, 46, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      130% 100% at 0% 100%,
      rgba(208, 45, 46, 0.5) 0%,
      rgba(0, 0, 0, 0) 87.68%
    );
}
</style>
